import React from "react";

const DetailHIOP = () => {
  return (
    <div className="text-sm w-full">
      <div className="flex my-4 w-full">
        <div className="w-[30%] text-[#6B7280]">Onset</div>
        <div className="w-[70%]">1 week ago</div>
      </div>
      <div className="flex my-4 w-full">
        <div className="w-[30%] text-[#6B7280]">Location</div>
        <div className="w-[70%]">Forehead and temples</div>
      </div>
      <div className="flex my-4 w-full">
        <div className="w-[30%] text-[#6B7280]">Duration</div>
        <div className="w-[70%]">Constant since onset</div>
      </div>
      <div className="flex my-4 w-full">
        <div className="w-[30%] text-[#6B7280]">Characteristics</div>
        <div className="w-[70%]">Dull ache</div>
      </div>
      <div className="flex my-4 w-full">
        <div className="w-[30%] text-[#6B7280]">Aggravating Factors</div>
        <div className="w-[70%]">
          Physical activity, bright light, loud noises
        </div>
      </div>
      <div className="flex my-4 w-full">
        <div className="w-[30%] text-[#6B7280]">Relieving Factors</div>
        <div className="w-[70%]">Rest in a dark, quiet room </div>
      </div>
      <div className="flex my-4 w-full">
        <div className="w-[30%] text-[#6B7280]">Associated Symptoms</div>
        <div className="w-[70%]">
          Occasional nausea, mild photophobia, mild phonophobia
        </div>
      </div>
      <div className="flex my-4 w-full">
        <div className="w-[30%] text-[#6B7280]">Medications Taken</div>
        <div className="w-[70%]">30Ibuprofen 200 mg, 3-4 daily</div>
      </div>
      <div className="flex my-4 w-full">
        <div className="w-[30%] text-[#6B7280]">Medical History</div>
        <div className="w-[70%]">Seasonal allergies, No drug allergies</div>
      </div>
      <div className="flex my-4 w-full">
        <div className="w-[30%] text-[#6B7280]">Stress Factors</div>
        <div className="w-[70%]">Work stress due to project deadlines</div>
      </div>
    </div>
  );
};

export default DetailHIOP;
