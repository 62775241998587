import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import SecondaryButton from "../../../../../common-components/Buttons/SecondaryButton";
import useGetAvailableRecord from "../hooks/useGetAvailableRecord";
import useLinkingTokenToABHA from "../hooks/useLinkingTokenToABHA";
import Skeleton from "react-loading-skeleton";
import HealthRecordButton from "../HealthRecordButton";
import PrescriptionRecord from "../prescription/PrescriptionRecord";
import OPconsultation from "../op-consultation/OPconsultation";
import WellnessRecords from "../wellness-record/WellnessRecords";
import Immunisations from "../immunisation/Immunisations";
import DischargeSummaryReport from "../discharge-summary/DischargeSummaryReport";
import HealthDocRecord from "../health-document/HealthDocRecord";
import DiagnosticReports from "../diagnostic-report/DiagnosticRecords";
import CreateRecord from "./hospital-record/CreateRecord";

const AvailableRecords = ({ setActiveHealthTab = () => {} }) => {
  const [careContextsId, setCareContextsId] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    isCareOpen: null,
    isPrescriptionOpen: null,
    isOPConsultation: null,
    isDiagnosticReport: null,
    isDischargeSummary: null,
    isImmunizationRecord: null,
    isWellnessRecord: null,
    isHealthDocumentRecord: null,
  });

  const [isCareDropDown, setIsCareDropDown] = useState(false);
  const [isCreateRecordModal, setIsCreateRecordModal] = useState(false);

  const {
    linkingStatus = {},
    isLinkingStatusLoading,
    isLinkingToken,
    onGenerateToken,
  } = useLinkingTokenToABHA();

  const {
    availableRecordData,
    isRecordLoading,
    onLinkRecord,
    isRecordLinking,
    getAvailableRecord,
  } = useGetAvailableRecord({ careContextsId });

  const handleCheckBoxChange = (value) => {
    if (careContextsId.includes(value)) {
      const newCareContextsId = careContextsId.filter((id) => id !== value);
      setCareContextsId(newCareContextsId);
    } else {
      setCareContextsId((prev) => [...prev, value]);
    }
  };

  return (
    <div>
      <div
        className="bg-white mt-4 p-6 rounded-lg text-sm overflow-x-scroll"
        style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
      >
        <div className="flex justify-between mb-4">
          <div className="text-[#2D2E33] font-medium mb-2">
            Hospital Records
          </div>
          <div className="flex gap-5 items-center">
            {isLinkingStatusLoading ? (
              <Skeleton width={160} height={36} />
            ) : (
              <>
                <SecondaryButton
                  buttonName="Create Record"
                  width="min-w-36"
                  onClick={() => setIsCreateRecordModal(true)}
                />
                {linkingStatus?.status === "ACTIVE" ? (
                  <SecondaryButton
                    buttonName="Link Documents"
                    onClick={onLinkRecord}
                    disabled={isRecordLinking}
                  />
                ) : (
                  <SecondaryButton
                    width="min-w-36"
                    buttonName="Generate Token"
                    onClick={onGenerateToken}
                    disabled={isLinkingToken}
                  />
                )}
              </>
            )}
            <div>
              <button
                onClick={() => setIsCareDropDown((prev) => !prev)}
                className="active:bg-[#1C64F2] px-4 w-48 gap-3 h-10 disabled:border-[#C6C7C9] flex items-center hover:border-2 hover:border-[#4C6AF7] border justify-center disabled:border disabled:bg-[#F9FAFB] disabled:text-[#A2A3A7]  border-[#4C6AF7] item-center py-2 rounded-lg text-sm text-[#4C6AF7]"
              >
                <span> Add New Document</span>
                <span>
                  <FontAwesomeIcon
                    icon={isCareDropDown ? faAngleUp : faAngleDown}
                  />
                </span>
              </button>

              {isCareDropDown && (
                <div className="absolute">
                  <HealthRecordButton setActiveHealthTab={setActiveHealthTab} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="font-medium justify-between bg-[#ECECED]  py-3 rounded px-5 flex ">
          <h1>Reference Name</h1>
          <div className="flex w-1/5 justify-between">
            <h1>Link Status</h1>
            <h1>No. of Documents</h1>
          </div>
        </div>
        <div>
          {(availableRecordData || []).map((item, index) => {
            const {
              careContexts = {},
              referenceId,
              display,
              noOfDocument,
            } = item || {};

            const {
              OPConsultation = [],
              DiagnosticReport = [],
              Prescription = [],
              DischargeSummary = [],
              ImmunizationRecord = [],
              HealthDocumentRecord = [],
              WellnessRecord = [],
            } = careContexts || {};

            return (
              <div key={index} className=" border-b p-4">
                {isRecordLoading ? (
                  <Skeleton width={100} />
                ) : (
                  <div className="flex items-center cursor-pointer">
                    <div className="flex gap-3 items-center w-[95%]">
                      <button
                        onClick={() =>
                          setSelectedItem((prev) => ({
                            ...prev,
                            isCareOpen:
                              prev.isCareOpen === referenceId
                                ? null
                                : referenceId,
                          }))
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            selectedItem.isCareOpen === referenceId
                              ? faAngleUp
                              : faAngleDown
                          }
                        />
                      </button>
                      <div>
                        <input type="checkbox" />
                      </div>
                      <div>
                        {referenceId} ({display})
                      </div>
                    </div>
                    <div className="flex w-[5%]">{noOfDocument}</div>
                  </div>
                )}
                {selectedItem.isCareOpen === referenceId ? (
                  <div>
                    {Prescription.length > 0 && (
                      <PrescriptionRecord
                        Prescription={Prescription}
                        handleCheckBoxChange={handleCheckBoxChange}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                      />
                    )}
                    {OPConsultation.length > 0 && (
                      <OPconsultation
                        OPConsultation={OPConsultation}
                        handleCheckBoxChange={handleCheckBoxChange}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                      />
                    )}
                    {DiagnosticReport.length > 0 && (
                      <DiagnosticReports
                        DiagnosticReport={DiagnosticReport}
                        handleCheckBoxChange={handleCheckBoxChange}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                      />
                    )}
                    {WellnessRecord.length > 0 && (
                      <WellnessRecords
                        WellnessRecord={WellnessRecord}
                        handleCheckBoxChange={handleCheckBoxChange}
                        selectedItem={selectedItem}
                        careContextsId={careContextsId}
                        setSelectedItem={setSelectedItem}
                      />
                    )}
                    {ImmunizationRecord.length > 0 && (
                      <Immunisations
                        ImmunizationRecord={ImmunizationRecord}
                        handleCheckBoxChange={handleCheckBoxChange}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                      />
                    )}
                    {DischargeSummary.length > 0 && (
                      <DischargeSummaryReport
                        DischargeSummary={DischargeSummary}
                        handleCheckBoxChange={handleCheckBoxChange}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                      />
                    )}
                    {HealthDocumentRecord.length > 0 && (
                      <HealthDocRecord
                        HealthDocumentRecord={HealthDocumentRecord}
                        handleCheckBoxChange={handleCheckBoxChange}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
      {isCreateRecordModal ? (
        <CreateRecord
          isCreateRecordModal={isCreateRecordModal}
          setIsCreateRecordModal={setIsCreateRecordModal}
          getAvailableRecord={getAvailableRecord}
        />
      ) : null}
    </div>
  );
};

export default AvailableRecords;
