import React from "react";

const PastVitals = () => {
  return (
    <div className="w-full overflow-scroll">
      <div className="w-full text-[#6B7280] flex mb-1 mt-2">
        <div className="w-1/3"></div>
        <div className="w-1/3">10/05/2024</div>
        <div className="w-1/3">14/05/2024</div>
      </div>
      <div className="w-full text-[#6B7280] flex mb-1 mt-2">
        <div className="w-1/3">BP</div>
        <div className="w-1/3 text-[#E02424]">138/94</div>
        <div className="w-1/3 text-[#E02424]">142/20</div>
      </div>
      <div className="w-full text-[#6B7280] flex mb-1 mt-2">
        <div className="w-1/3">Resp</div>
        <div className="w-1/3 text-[#1F2A37]">10/05/2024</div>
        <div className="w-1/3 text-[#1F2A37]">10/05/2024</div>
      </div>
      <div className="w-full text-[#6B7280] flex mb-1 mt-2">
        <div className="w-1/3">Temp</div>
        <div className="w-1/3 text-[#1F2A37]">37 C (98.6 F)</div>
        <div className="w-1/3 text-[#1F2A37]">35 C (98.6 F)</div>
      </div>
      <div className="w-full text-[#6B7280] flex mb-1 mt-2">
        <div className="w-1/3">Temp SRC</div>
        <div className="w-1/3 text-[#1F2A37]">Tympanic </div>
        <div className="w-1/3 text-[#1F2A37]">Tympanic </div>
      </div>
      <div className="w-full text-[#6B7280] flex mb-1 mt-2">
        <div className="w-1/3">Weight</div>
        <div className="w-1/3 text-[#1F2A37]">85.7 kg (189 lb)</div>
        <div className="w-1/3 text-[#1F2A37]">85.1 kg (189 lb)</div>
      </div>
      <div className="w-full text-[#6B7280] flex mb-1 mt-2">
        <div className="w-1/3">Height</div>
        <div className="w-1/3 text-[#1F2A37]">167.6 cm (66”)</div>
        <div className="w-1/3 text-[#1F2A37]">167.6 cm (66”) </div>
      </div>
      <div className="w-full text-[#6B7280] flex mb-1 mt-2">
        <div className="w-1/3">BMI</div>
        <div className="w-1/3 text-[#1F2A37]">30.5</div>
        <div className="w-1/3 text-[#1F2A37]">30.4</div>
      </div>
    </div>
  );
};

export default PastVitals;
