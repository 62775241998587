import React from "react";
import Modal from "../../../common-components/Modal";
import PresentIllnesHistory from "./PresentIllnesHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import DifferentialDiagnisis from "./DifferentialDiagnisis";
import Allergies from "./Allergies";
import PastMedications from "./PastMedications";
import PreventiveCare from "./PreventiveCare";
import PastVitals from "./PastVitals";
import CurrentMedications from "./CurrentMedications";
import MedicalHistory from "./MedicalHistory";
import PastSurgeries from "./PastSurgeries";
import FamilyHistory from "./FamilyHistory";
import SocialHistory from "./SocialHistory";
import PastVisitSummary from "./PastVisitSummary";
import Immunization from "./Immunization";
import ToggleToParagraph from "../../../../images/paragraph.svg";
import DetailHIOP from "./DetailHIOP";

const PatientDetailInModal = ({ showExpandModal, setShowExpandModal }) => {
  const COMPONENT_MAPPING = {
    hopi: PresentIllnesHistory,
    differential_diagnosis: DifferentialDiagnisis,
    allergies: Allergies,
    past_medications: PastMedications,
    preventive_care: PreventiveCare,
    past_vitals: PastVitals,
    current_medications: CurrentMedications,
    medical_history: MedicalHistory,
    past_surgeries: PastSurgeries,
    family_history: FamilyHistory,
    social_history: SocialHistory,
    past_visit_summary: PastVisitSummary,
    immunization_injections: Immunization,
    paragraph: DetailHIOP,
  };

  const HEADER_MAPPING = {
    hopi: "History of Present Illness",
    differential_diagnosis: "Differential Diagnosis",
    allergies: "Allergies",
    past_medications: "Past Medications",
    preventive_care: "Preventive Care",
    past_vitals: "Past Vitals",
    current_medications: "Current Medications",
    medical_history: "Medical History",
    past_surgeries: "Past Surgeries",
    family_history: "Family History",
    social_history: "Social History",
    past_visit_summary: "Past Visit Summary",
    immunization_injections: "Immunization/Injections",
    paragraph: "History of Present Illness",
  };

  const Component = COMPONENT_MAPPING[showExpandModal] || null;
  if (!Component) {
    return null;
  }

  const FIND_COMPONENT = [
    "hopi",
    "differential_diagnosis",
    "allergies",
    "past_medications",
    "preventive_care",
    "past_vitals",
    "current_medications",
    "medical_history",
    "past_surgeries",
    "family_history",
    "social_history",
    "past_visit_summary",
    "immunization_injections",
  ];

  const handleNextModal = () => {
    let checkModal = false;
    for (let i = 0; i < FIND_COMPONENT.length; i++) {
      if (showExpandModal === FIND_COMPONENT[i]) {
        checkModal = true;
        setShowExpandModal(FIND_COMPONENT[i + 1]);
      }
    }
    if (checkModal === false) {
      setShowExpandModal("differential_diagnosis");
    }
  };

  const handlePrevModal = () => {
    let checkModal = false;
    for (let i = 0; i < FIND_COMPONENT.length; i++) {
      if (showExpandModal === FIND_COMPONENT[i]) {
        checkModal = true;
        setShowExpandModal(FIND_COMPONENT[i - 1]);
      }
    }
    if (checkModal === false) {
      setShowExpandModal("");
    }
  };

  return (
    <div>
      <Modal
        modalWidth="w-[70%]"
        modalHeight="min-h-[50%]"
        showModal={showExpandModal === "" ? false : true}
        onClose={() => setShowExpandModal("")}
      >
        <div className="py-2 text-sm px-5">
          <div className="flex justify-between items-center">
            <div className="w-full flex items-center">
              <button
                onClick={() => handlePrevModal()}
                className="hover:bg-slate-100 rounded px-2.5 py-1"
              >
                <FontAwesomeIcon icon={faAngleLeft} color="#9CA3AF" />
              </button>
              <button
                onClick={() => handleNextModal()}
                className="hover:bg-slate-100 px-2.5 rounded py-1"
              >
                <FontAwesomeIcon icon={faAngleRight} color="#9CA3AF" />
              </button>
              <div className="text-[#111928] mx-5 text-base font-medium">
                {HEADER_MAPPING[showExpandModal]}
              </div>
              <button className="flex justify-center">
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  color="#6B7280"
                />
              </button>
            </div>
            {showExpandModal === "hopi" || showExpandModal === "paragraph" ? (
              <button
                className="me-5 hover:bg-slate-100 px-2.5 rounded py-1"
                onClick={() =>
                  setShowExpandModal(
                    showExpandModal === "paragraph" ? "hopi" : "paragraph"
                  )
                }
              >
                <img src={ToggleToParagraph} alt="toggletoParagraph" />
              </button>
            ) : null}
          </div>
          <hr className="my-2 -mx-5"></hr>
          <Component />
        </div>
      </Modal>
    </div>
  );
};

export default PatientDetailInModal;
