import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const PastMedications = () => {
  return (
    <div className="flex w-full py-4 text-[#374151] text-sm">
      <div className="w-[70%]">
        <div>RKESTRA L (Levosulipride (75mg) + Rabeprazole (20mg)</div>
        <div className="mt-1 font-light">
          1 tablet | 1-0-1 | After meal | 7d
        </div>
      </div>
      <div className="w-[30%] flex justify-center">17/05/2024</div>
      <div className="bg-[#FDE8E8] px-1 w-fit h-fit rounded-full">
        <FontAwesomeIcon icon={faCircleExclamation} color="#9B1C1C" />
      </div>
    </div>
  );
};

export default PastMedications;
