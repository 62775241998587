import React from "react";
import SecondaryButton from "../../../../../common-components/Buttons/SecondaryButton";
import FileUpload from "../../../../../../images/file_upload.svg";
import TertiaryButton from "../../../../../common-components/Buttons/TertiaryButton";
import PrimaryButton from "../../../../../common-components/Buttons/PrimaryButton";
import Modal from "../../../../../common-components/Modal";

const AddWellnessRecord = ({
  activeHealthTab,
  setActiveHealthTab = () => {},
}) => {
  return (
    <div className="text-sm text-[#2D2E33">
      <Modal
        showModal={activeHealthTab === "wellness_record" ? true : false}
        onClose={() => setActiveHealthTab("")}
        modalHeight="min-h-1/2"
        modalWidth="w-[90%]"
      >
        <div className="py-4 px-6">
          <h1 className="text-[#2D2E33 text-base  font-medium">
            New Wellness Record
          </h1>
          <div className="flex py-4">
            <div className="w-8/12">
              <div className="mb-5">
                <h1 className="font-medium bg-[#ECECED] py-1 rounded ps-4">
                  Vitals
                </h1>
                <div className="flex ps-4 mt-2 justify-between flex-wrap">
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Height</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Height"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Weight</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Weight"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Temperature</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Temperature"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Pulse Rate</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Pulse Rate"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Breath Rate</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Breath Rate"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <h1 className="font-medium bg-[#ECECED] py-1 rounded ps-4">
                  Blood Pressure
                </h1>
                <div className="flex mt-2 ps-4 gap-5 flex-wrap">
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">
                      Systolic Pressure
                    </div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="Sys. Pressure"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">
                      Diastolic Pressure
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Dia. Pressure"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h1 className="font-medium bg-[#ECECED] py-1 rounded ps-4">
                  Life Style
                </h1>
                <div className="flex mt-2 ps-4 gap-5 flex-wrap">
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">Do you Smoke?</div>
                    <div>
                      <input
                        type="text"
                        placeholder="select"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/4">
                    <div className="text-[#5E6066] mb-2.5">
                      How many hours you sleep?
                    </div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b"
                        placeholder="No. of hours"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 justify-end">
                <TertiaryButton buttonName="Reset" width="w-fit" />
                <PrimaryButton buttonName="Submit" width="w-fit" />
              </div>
            </div>
            <div className="text-[#7F8185] flex flex-col items-center w-[10%] justify-center">
              <div className="flex flex-col items-center">
                <div className="border-l h-56"></div>
                <div>or</div>
                <div className="border-l h-56"></div>
              </div>
            </div>
            <div className="border-dashed h-fit border gap-3 flex flex-col rounded-lg py-6 px-12 bg-[#F9FAFB] border-[#C6C7C9] justify-center items-center">
              <div className="flex gap-2 justify-center flex-col">
                <img
                  src={FileUpload}
                  alt="FileUpload"
                  className="h-12 w-12 mx-auto"
                />
                <h1 className="text-[#2D2E33] text-sm">
                  Drag & Drop File to Upload
                </h1>
                <h1 className="text-[#7F8185] mx-auto">.png/ .jpg/ .pdf</h1>
              </div>
              <div>or</div>
              <SecondaryButton buttonName="Browse Files" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddWellnessRecord;
