import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import file from "../../../../../../images/file.svg";

const Immunisations = ({
  ImmunizationRecord = [],
  handleCheckBoxChange = () => {},
  selectedItem,
  setSelectedItem,
}) => {
  return (
    <div className="ms-10">
      <div className="flex items-center mt-3 ">
        <div className="flex items-center gap-3 w-[95%]">
          <button
            onClick={() =>
              setSelectedItem((prev) => ({
                ...prev,
                isImmunizationRecord: !prev.isImmunizationRecord,
              }))
            }
          >
            <FontAwesomeIcon
              icon={selectedItem.isImmunizationRecord ? faAngleUp : faAngleDown}
            />
          </button>
          <div>
            <input type="checkbox" />
          </div>
          <div>Immunisations Report</div>
        </div>
        <div className="">{ImmunizationRecord.length}</div>
      </div>
      {selectedItem.isImmunizationRecord ? (
        <div>
          {ImmunizationRecord?.map((immunisation, index) => {
            const { id, display, linked } = immunisation || {};
            return (
              <div className="p-4 items-center flex border-b" key={index}>
                <div className="text-[#4C6AF7]  w-[78.5%] gap-3 items-center flex ms-6 border-[#D8E3FF]">
                  <div>
                    {!linked && (
                      <input
                        type="checkbox"
                        onChange={() => handleCheckBoxChange(id)}
                      />
                    )}
                  </div>
                  <div className="mb-1">
                    <img src={file} alt="file" />
                  </div>
                  <div>
                    {id} ({display})
                  </div>
                </div>
                <div>{linked === true ? "Linked" : "Un Linked"}</div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Immunisations;
