import React from "react";

const DifferentialDiagnisis = () => {
  return (
    <div className="w-full">
      <div className="pt-3">Sinus Headache</div>
      <div className="pt-3">Hangover headache</div>
      <div className="py-3">Stroke headache</div>
    </div>
  );
};

export default DifferentialDiagnisis;
