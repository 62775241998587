import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Bp from "../../../../images/bp.svg";
import React from "react";
import { useDispatch } from "react-redux";
import { setActiveDoctorTab } from "../../../../redux-store/slice/activeDoctorTab";

const VITALS = [
  {
    day: "Tuesday",
    date: "06 Sep 2024",
    bloodPressure: "128/89",
    bmi: "31.83 kg/m2",
    respiratoryRate: "-",
    temperature: "39 C (98.6F)",
    temperatureSRC: "tympanic",
    weight: "85.7kg (189lb)",
    height: "167.6 cm",
  },
  {
    day: "Tuesday",
    date: "28 Aug 2024",
    bloodPressure: "138/94",
    bmi: "30.83 kg/m2",
    respiratoryRate: "-",
    temperature: "27 C (98.6F)",
    temperatureSRC: "tympanic",
    weight: "85.7kg (189lb)",
    height: "167.6 cm",
  },
  {
    day: "Tuesday",
    date: "21 Aug 2024",
    bloodPressure: "148/98",
    bmi: "33.83 kg/m2",
    respiratoryRate: "-",
    temperature: "38 C (98.6F)",
    temperatureSRC: "tympanic",
    weight: "85.7kg (189lb)",
    height: "167.6 cm",
  },
];

const PatientVitals = () => {
  const dispatch = useDispatch();
  return (
    <div
      style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
      className="bg-white h-screen overflow-y-scroll border border-t-0 border-r-0 mr-0.5"
    >
      <div className="flex border-b fixed w-[25%] py-2 ps-3 pe-5 justify-between items-center bg-white">
        <div className="text-[#6B7280] uppercase text-sm">VITALS</div>
        <button
          onClick={() => {
            dispatch(setActiveDoctorTab(null));
          }}
        >
          <FontAwesomeIcon icon={faXmark} color="#1F2A37" />
        </button>
      </div>

      <div className="pt-10 px-3 pb-36">
        {VITALS.map((vital, index) => {
          const { date, day } = vital || {};
          return (
            <div className="py-2" key={index}>
              <div>
                {day} {date}
              </div>
              <div className="shadow mt-2 p-2 rounded-md">
                <div className="text-[#E02424] flex items-center gap-4 font-medium text-xl">
                  {vital.bloodPressure}
                  <span className="bg-[#FDE8E8] rounded-full flex justify-center px-2 py-1.5 items-center">
                    <img src={Bp} alt="bp" />
                  </span>
                </div>
                <div className="text-[#6B7280] text-sm mt-2">
                  Blood Pressure{" "}
                </div>
                <div className="flex my-2 w-full">
                  <div className="w-1/2 text-[#6B7280] text-sm font-light">
                    BMI
                  </div>
                  <div className="w-1/2 text-[#111928] text-sm">
                    {vital.bmi}
                  </div>
                </div>
                <div className="flex my-2 w-full">
                  <div className="w-1/2 text-[#6B7280] text-sm font-light">
                    Respiratory rate
                  </div>
                  <div className="w-1/2 text-[#111928] text-sm">
                    {vital.respiratoryRate}
                  </div>
                </div>
                <div className="flex my-2 w-full">
                  <div className="w-1/2 text-[#6B7280] text-sm font-light">
                    Temperature
                  </div>
                  <div className="w-1/2 text-[#111928] text-sm">
                    {vital.temperature}
                  </div>
                </div>
                <div className="flex my-2 w-full">
                  <div className="w-1/2 text-[#6B7280] text-sm font-light">
                    Temperature SRC
                  </div>
                  <div className="w-1/2 text-[#111928] text-sm">
                    {vital.temperatureSRC}
                  </div>
                </div>
                <div className="flex my-2 w-full">
                  <div className="w-1/2 text-[#6B7280] text-sm font-light">
                    Weight
                  </div>
                  <div className="w-1/2 text-[#111928] text-sm">
                    {vital.weight}
                  </div>
                </div>
                <div className="flex my-2 w-full">
                  <div className="w-1/2 text-[#6B7280] text-sm font-light">
                    Height
                  </div>
                  <div className="w-1/2 text-[#111928] text-sm">
                    {vital.height}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PatientVitals;
