import axios from "axios";
import { useCallback, useEffect, useState } from "react";

const useGetAllDrug = () => {
  const [drugList, setDrugList] = useState([]);
  const [isDrugListLoading, setIsDrugListLoading] = useState(false);

  const getAllDrugList = useCallback(async (inputValue = "") => {
    setIsDrugListLoading(true);
    const token = localStorage.getItem("authToken");
    try {
      const resp = await axios.get(`/v2/scroll/drug/search`, {
        params: {
          query: inputValue,
        },
        headers: {
          Authorization: token,

          // `Bearer ${token}`,
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      setDrugList(resp.data);
    } catch (error) {
      console.log(error, "error");
    }
    setIsDrugListLoading(false);
  }, []);

  useEffect(() => {
    getAllDrugList();
  }, [getAllDrugList]);

  return {
    isDrugListLoading,
    drugList,
    getAllDrugList,
  };
};
export default useGetAllDrug;
