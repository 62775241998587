import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Eye from "../../../../images/grayEye.svg";
import Duplicate from "../../../../images/duplicate.svg";
import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDoctorTab } from "../../../../redux-store/slice/activeDoctorTab";
import { setPrescriptionData } from "../../../../redux-store/slice/prescriptionDataSlice";
import { useNavigate } from "react-router-dom";
import useSavePrescription from "./hooks/useSavePrescription";
import { ToastContainer } from "react-toastify";

const PAST_PRESCRIPTIONS = [
  {
    chiefComplaint: "Headache",
    complaints: [],
    templateName: "",
    diagnosis: ["Fever", "Cold", "Painful Swallowing"],
    rxList: [
      {
        drugName: "RKESTRA L",
        dose: "500",
        measurement: "mg",
        frequency: "1-0-0",
        timing: "after meal",
        companyLingo: "",
        duration: "5 days",
        notes: "",
      },
      {
        drugName: "silver sulfadiazine cream",
        dose: "",
        measurement: "",
        timing: "When pain start",
        duration: "5 days",
        frequency: "1-1-1",
        notes: null,
        companyLingo: "",
      },
      {
        drugName: "povidone iodine",
        dose: "400",
        measurement: "mg",
        timing: "SOS",
        duration: "3 days",
        frequency: "1-1-1",
        notes: null,
        companyLingo: "",
      },
      {
        drugName: "collagen dressing",
        dose: "300",
        measurement: "mg",
        timing: "SOS",
        duration: "2 days",
        frequency: "0-1-0",
        notes: null,
        companyLingo: "",
      },
      {
        drugName: "moxifloxacin",
        dose: "400",
        measurement: "mg",
        timing: "After meal",
        duration: "5 days",
        frequency: "0-1-0",
        notes: null,
        companyLingo: "",
      },
    ],
    labInvestigations: ["CBC", "LFT", "KFT"],
    instructions: [
      "Drink water minimum 6 ltr per day\n Do exercise regularly. \n Eat seasonal fruits and green vegetablesTake medicine after meal. \nAvoid taking oily food.",
    ],
    notes: ["please keep distance with others"],
    followUp: "5 days",
    prescribeDate: "21/08/2024",
  },
];

const PastPrescription = () => {
  const navigate = useNavigate();

  const patientData = useSelector((state) => state.patientDetails);
  const dispatch = useDispatch();

  const { patientMRNo } = patientData || {};

  const {
    //  isPrescriptionSaving,
    // pastPrescription,
    onSavePrescription,
  } = useSavePrescription({
    method: "get",
  });

  useEffect(() => {
    onSavePrescription(patientMRNo);
  }, [patientMRNo, onSavePrescription]);

  return (
    <div
      style={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)" }}
      className="bg-white h-screen overflow-y-scroll border border-t-0 border-r-0 mr-0.5"
    >
      <ToastContainer />
      <div className="flex border-b fixed w-[25%] py-2 ps-3 pe-5 justify-between items-center bg-white">
        <div className="text-[#6B7280] uppercase text-sm">
          Past Prescription
        </div>
        <button
          onClick={() => {
            dispatch(setActiveDoctorTab(null));
          }}
        >
          <FontAwesomeIcon icon={faXmark} color="#1F2A37" />
        </button>
      </div>

      <div className="pt-10 pb-32">
        {PAST_PRESCRIPTIONS.map((prescription, index) => {
          return (
            <div key={index} className="m-3 shadow-sm p-2 rounded-md border">
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => {
                    dispatch(setPrescriptionData(prescription));
                    navigate("/templatePreview");
                  }}
                >
                  <img src={Eye} alt="eye" />
                </button>
                <button
                  onClick={() => dispatch(setPrescriptionData(prescription))}
                >
                  <img src={Duplicate} alt="Duplicate" />
                </button>
              </div>
              <div className="flex items-center mt-1">
                <div className="flex w-[70%] flex-wrap">
                  {prescription.chiefComplaint}
                </div>
                <div className="text-[#4B5563] text-sm">
                  {prescription.prescribeDate}
                </div>
              </div>
              <div className="text-[#1F2A37] text-sm">
                {prescription.diagnosis.map((item, index) => {
                  return (
                    <span key={index}>
                      {item} <span className="pe-2">|</span>
                    </span>
                  );
                })}
              </div>
              <div className="text-sm font-light text-[#374151]">
                {prescription.labInvestigations.map((item, index) => {
                  return (
                    <span key={index}>
                      {item}
                      <span className="px-2">|</span>
                    </span>
                  );
                })}
              </div>
              <div>
                {prescription.rxList.map((item, index) => {
                  const {
                    drugName,
                    dose,
                    measurement,
                    frequency,
                    duration,
                    timing,
                  } = item || {};
                  return (
                    <div key={index}>
                      <div className="text-[#1F2A37]">
                        {drugName} ({dose} {measurement})
                      </div>
                      <div className="text-sm font-light text-[#374151]">
                        {frequency} | {timing} | {duration}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(PastPrescription);
