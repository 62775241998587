import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

const useGetAllInvestigations = () => {
  const [labInvestigations, setLabInvestigations] = useState([]);
  const [isLabInvestigationLoading, setIsLabInvestigationLoading] =
    useState(true);

  const getAllInvestigations = useCallback(async (inputValue = "") => {
    const token = localStorage.getItem("authToken");
    try {
      const res = await axios.get(`/v2/cortex/investigations/search`, {
        params: { keyword: inputValue },
        headers: { Authorization: token },
      });
      setLabInvestigations(res.data);
    } catch (error) {
      console.error(error, "error");
      toast.error("Oops somthing went wrong", {
        position: "top-center",
      });
    }
    setIsLabInvestigationLoading(false);
  }, []);

  useEffect(() => {
    getAllInvestigations();
  }, [getAllInvestigations]);

  return {
    labInvestigations,
    isLabInvestigationLoading,
    getAllInvestigations,
  };
};

export default useGetAllInvestigations;
