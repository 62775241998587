import React, { useState } from "react";
import DoctorHeader from "./header/DoctorHeader";
import PatientDetails from "./patient-details/PatientDetails";
import DoctorSideBar from "./side-bar/DoctorSideBar";
import PatientMedicalDetails from "./patient-details/PatientMedicalDetails";
import PastPrescription from "./create-prescription/PastPrescription";
import { useSelector } from "react-redux";
import PatientDetailInModal from "./patient-details-in-modal/PatientDetailInModal";
import ReferalModal from "./ReferalModal";
import Notifications from "./Notifications";
import PatientVitals from "./side-bar/PatientVitals";
import DoctorNotes from "./side-bar/DoctorNotes";
import DoctorNotesModal from "./side-bar/DoctorNotesModal";
import Users from "./side-bar/Users";
import PatientHealthRecord from "./patient-details/patient-health-records/PatientHealthRecord";
import ConsentManager from "./patient-details/consent-manager/ConsentManager";

const Doctor = () => {
  const activeTab = useSelector((state) => state.activeDoctorTab.activeTab);
  const [showExpandModal, setShowExpandModal] = useState("");
  const [referalModal, setReferalModal] = useState(false);
  const [consentModal, setConsentModal] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const isDoctorNotes = useSelector((state) => state.doctorNotes);
  const [activePatientDetailsTab, setActivePatientDetailsTab] =
    useState("patient_details");

  return (
    <div className="w-full relative">
      <div className="w-full border-b border-[#D8E3FF] bg-white fixed">
        <DoctorHeader
          setReferalModal={setReferalModal}
          setNotifications={setNotifications}
        />
        {/* <PatientFilter
          setReferalModal={setReferalModal}
          setNotifications={setNotifications}
        /> */}
      </div>
      <div className="w-full">
        <div style={{ paddingTop: "47px" }} className="flex w-full">
          <div
            className={`${activeTab === null ? "w-[96%]" : "w-[71%]"} mr-auto`}
          >
            <div className="w-[96%] fixed">
              <PatientDetails
                activePatientDetailsTab={activePatientDetailsTab}
                setActivePatientDetailsTab={setActivePatientDetailsTab}
              />
            </div>
            <div className="w-full mt-44 p-8">
              {activePatientDetailsTab === "patient_details" && (
                <PatientMedicalDetails
                  setShowExpandModal={setShowExpandModal}
                />
              )}
              {activePatientDetailsTab === "health_records" && (
                <PatientHealthRecord />
              )}
              {activePatientDetailsTab === "consent_manager" && (
                <ConsentManager
                  consentModal={consentModal}
                  setConsentModal={setConsentModal}
                />
              )}
            </div>
          </div>
          {activeTab === "Rx" ? (
            <div className="w-[25%] mt-4 fixed right-14">
              <PastPrescription />
            </div>
          ) : null}

          {activeTab === "vital" ? (
            <div className="w-[25%] mt-4 fixed right-14">
              <PatientVitals />
            </div>
          ) : null}
          {activeTab === "medical-note" ? (
            <div className="w-[25%] mt-4 fixed right-14">
              <DoctorNotes />
            </div>
          ) : null}
          {activeTab === "user" ? (
            <div className="w-[25%] mt-4 fixed right-14">
              <Users />
            </div>
          ) : null}
          <div className="fixed ml-auto z-50 h-full right-0">
            <DoctorSideBar />
          </div>
        </div>
      </div>
      {showExpandModal !== "" ? (
        <PatientDetailInModal
          showExpandModal={showExpandModal}
          setShowExpandModal={setShowExpandModal}
        />
      ) : null}
      {referalModal ? (
        <ReferalModal
          referalModal={referalModal}
          setReferalModal={setReferalModal}
        />
      ) : null}
      {notifications ? (
        <div className="fixed w-1/4 z-50 top-24 right-6">
          <Notifications
            notifications={notifications}
            setNotifications={setNotifications}
          />
        </div>
      ) : null}
      {isDoctorNotes ? (
        <div>
          <DoctorNotesModal />
        </div>
      ) : null}
    </div>
  );
};

export default Doctor;
