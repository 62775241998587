import React, { useState } from "react";
import AvailableRecords from "./available-record/AvailableRecords";
import RequestedRecord from "./available-record/requested-record/RequestedRecord";
import AddWellnessRecord from "./wellness-record/AddWellnessRecord";
import AddHealthDocument from "./health-document/AddHealthDocument";
import AddImmunisationRecord from "./immunisation/AddImmunisationRecord";
import AddOPconsultation from "./op-consultation/AddOPconsultation";
import AddDiagnosticReport from "./diagnostic-report/AddDiagnosticReport";
import AddDischargeSummary from "./discharge-summary/AddDischargeSummary";

const PatientHealthRecord = () => {
  const [activeHealthTab, setActiveHealthTab] = useState("");
  const MODAL_COMPONENT_MAPPING = {
    wellness_record: AddWellnessRecord,
    health_document: AddHealthDocument,
    add_immunisation_record: AddImmunisationRecord,
    op_consultation: AddOPconsultation,
    diagnostic_report: AddDiagnosticReport,
    discharge_summary: AddDischargeSummary,
  };

  const SubmitRecordComponent =
    MODAL_COMPONENT_MAPPING[activeHealthTab] || null;

  return (
    <div>
      <AvailableRecords setActiveHealthTab={setActiveHealthTab} />

      <RequestedRecord />

      {SubmitRecordComponent ? (
        <SubmitRecordComponent
          activeHealthTab={activeHealthTab}
          setActiveHealthTab={setActiveHealthTab}
        />
      ) : null}
    </div>
  );
};

export default PatientHealthRecord;
