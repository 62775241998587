import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const PastVisitSummary = () => {
  return (
    <div className="w-full flex pt-3 justify-between ">
      <div className="w-full flex mt-3 text-[#111928] text-sm">
        Acute Gastritis
      </div>
      <div className="w-full my-2 text-[#374151] text-sm">
        <div className="text-[#1F2A37] font-medium">
          Fever | Cold | Painful Swallowing
        </div>
        <div className="font-light">CBC | LFT | KFT</div>
      </div>
      <div className="w-full py-1 text-[#1F2A37] font-medium text-sm">
        30/01/2024
      </div>
      <div className="">
        <FontAwesomeIcon icon={faPenToSquare} color="#374151" />
      </div>
    </div>
  );
};

export default PastVisitSummary;
