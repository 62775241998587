import React, { memo, useState } from "react";
import PrimaryButton from "../../../../common-components/Buttons/PrimaryButton";
import Select from "../../../../common-components/Select";
import { SERVICE_TYPE, TAGS } from "../../../../constants/Constant";
// import SelectTimeSlotModal from "../SelectTimeSlotModal";
import useCheckIn from "./hooks/useCheckIn";
import useGetPatientList from "./hooks/useGetPatientList";
import useGetAllCompliants from "../../../doctor/create-prescription/hooks/useGetAllCompliants";
import { formatArrayOfObject } from "../../../../utils/formateArray";
import SearchAndSelect from "../../../../common-components/SearchAndSelect";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardDrawer } from "../../../../../redux-store/slice/checkInDrawerSlice";
import SearchSelectFromAPI from "../../../../common-components/SearchSelectFromAPI";
import MultiSelectWithSearch from "../../../../common-components/MultiSelectWithSearch";
import {
  faAngleDown,
  faAngleUp,
  faClock,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import SelectTimeSlotModal from "./SelectTimeSlotModal";
import closeCross from "../../../../../images/close_cross.svg";
import { formatDateTime } from "../../../../utils/formatDateTime";

const CreateAppointment = ({ patient = {} }) => {
  const dashboardDrawer = useSelector((state) => state.dashboardDrawer);
  const dispatch = useDispatch();
  const [appointmentData, setAppointmentData] = useState({
    timeSlot: formatDateTime(new Date()),
    mrNo: "",
    serviceType: "",
    tags: [],
    chiefComplaint: "",
    visitType: "INPERSON",
    department: "GENERAL",
    doctorId: null,
    appointmentDetails: null,
  });
  const [timeSlot, setTimeSlot] = useState(false);
  const [isOnClear, setIsOnClear] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointmentData((prev) => ({ ...prev, [name]: value }));
  };

  const isFormValid = () => {
    const requiredFields = ["patientMRNo", "chiefComplaint", "serviceType"];
    return requiredFields.every((field) => appointmentData[field] !== "");
  };

  const { chiefComplaints, isChiefComplaintsLoading, getAllChiefComplaints } =
    useGetAllCompliants();

  const { isCheckInLoading, onCheckIn } = useCheckIn({
    appointmentData,
    setAppointmentData,
    setIsOnClear,
  });
  const { patientList, getPatientList, isPatientListLoading } =
    useGetPatientList();

  const onCloseDrawer = () => {
    dispatch(
      setDashboardDrawer({
        userDrawer: false,
        skipABHA: false,
        checkInDrawer: !dashboardDrawer.checkInDrawer,
      })
    );
  };

  return (
    <div className="mx-3 my-2">
      <div className="flex justify-between">
        <h1 className="text-[#6B7280] text-base uppercase font-medium">
          New Appointment
        </h1>
        <button onClick={() => onCloseDrawer()} className="text-[#6B7280]">
          <img src={closeCross} alt="closeCross" />
        </button>
      </div>
      <div>
        <form className="pb-4">
          <div className="flex flex-col pb-3 my-2">
            <label className="text-sm mb-0.5 text-[#111928]">Slot</label>
            <div className="relative">
              <div className="absolute left-4 top-2">
                <FontAwesomeIcon icon={faClock} size="sm" color="#6B7280" />
              </div>
              <input
                name="timeSlot"
                value={appointmentData.timeSlot}
                onChange={handleChange}
                placeholder="Pick up Slot"
                readOnly
                onClick={() => {
                  setTimeSlot(true);
                }}
                className="focus:outline-none text-[#2D2E33] font-normal bg-[#F9FAFB] placeholder:font-normal rounded-lg ps-10 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2.5 w-full focus:border-[#2D2E33]"
              />
            </div>
          </div>
          <div className="flex flex-col pb-3 my-2">
            <label className="text-sm mb-0.5 text-[#111928]">Patient*</label>
            <SearchAndSelect
              prefixIcon={faUser}
              name="mrNo"
              onChange={handleChange}
              patientList={patientList}
              getPatientList={getPatientList}
              isOnClear={isOnClear}
              setIsOnClear={setIsOnClear}
              isPatientListLoading={isPatientListLoading}
              defaultOptions={patient}
              placeholder="Select Patient"
              className="focus:outline-none text-[#2D2E33] font-normal bg-[#F9FAFB] placeholder:font-normal rounded-lg ps-10 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2.5 w-full focus:border-[#2D2E33]"
            />
          </div>
          <div className="flex flex-col pb-1 my-2">
            <label className="text-sm mb-0.5 text-[#111928]">
              Service Type*
            </label>
            <Select
              name="serviceType"
              options={SERVICE_TYPE}
              readOnly
              upIcon={faAngleUp}
              downIcon={faAngleDown}
              openTopPosition="top-2"
              closeTopPosition="top-2"
              onChange={handleChange}
              placeholder="Select"
              required={true}
              showIcon={true}
              isOnClear={isOnClear}
              setIsOnClear={setIsOnClear}
              className="focus:outline-none text-[#2D2E33] font-normal bg-[#F9FAFB] placeholder:font-normal rounded-lg ps-4 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2.5 w-full focus:border-[#2D2E33]"
            />
          </div>
          <div className="flex flex-col pb-1 my-2">
            <label className="text-sm mb-0.5 text-[#111928]">
              Chief Complaints*
            </label>

            <SearchSelectFromAPI
              getData={getAllChiefComplaints}
              options={formatArrayOfObject(chiefComplaints)}
              isLoading={isChiefComplaintsLoading}
              name="chiefComplaint"
              onChange={handleChange}
              defaultOptions={{}}
              isOnClear={isOnClear}
              setIsOnClear={setIsOnClear}
              placeholder="Type"
              className="focus:outline-none text-[#2D2E33] font-normal bg-[#F9FAFB] placeholder:font-normal rounded-lg ps-4 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm py-2.5 w-full focus:border-[#2D2E33]"
            />
          </div>
          <div className="flex flex-col my-2 pb-4">
            <label className="text-sm mb-0.5 text-[#111928]">Tags</label>

            <MultiSelectWithSearch
              options={TAGS}
              multiple={true}
              showIcon={true}
              readOnly={false}
              required={false}
              onChange={handleChange}
              placeholder="Select"
              name="tags"
              labelKey="label"
              valueKey="value"
              isOnClear={isOnClear}
              setIsOnClear={setIsOnClear}
              showPrefixIcon={false}
              upIcon={faAngleUp}
              downIcon={faAngleDown}
              deleteIcon={faXmark}
              defaultOptions={[]}
              openTopPosition="top-2"
              closeTopPosition="top-2"
              className="focus:outline-none text-[#2D2E33] bg-[#F9FAFB] font-normal rounded-lg ps-4 border placeholder:text-[#9CA3AF] placeholder:font-normal  border-[#D1D5DB] text-sm py-2.5 w-full focus:border-[#2D2E33]"
            />
          </div>

          <PrimaryButton
            disabled={!isFormValid() || isCheckInLoading}
            buttonName="Check-In"
            width="w-full"
            type="button"
            onClick={(e) => onCheckIn(e)}
          />
        </form>
        {timeSlot ? (
          <SelectTimeSlotModal
            timeSlot={timeSlot}
            setTimeSlot={setTimeSlot}
            setAppointmentData={setAppointmentData}
          />
        ) : null}
      </div>
    </div>
  );
};

export default memo(CreateAppointment);
