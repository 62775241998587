import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

const useGetAllDiagnosis = () => {
  const [differentialDiagnosis, setDifferentialDiagnosis] = useState([]);
  const [isdiagnosisLoading, setIsDiagnoisLoading] = useState(true);

  const getAllDiagnosis = useCallback(async (inputValue = "") => {
    const token = localStorage.getItem("authToken");
    try {
      const res = await axios.get(`/v2/cortex/diagnosis/search`, {
        params: { keyword: inputValue },
        headers: {
          Authorization: token,
        },
      });
      setDifferentialDiagnosis(res.data);
    } catch (error) {
      console.error(error, "error");
      toast.error("Oops somthing went wrong", {
        position: "top-center",
      });
    }
    setIsDiagnoisLoading(false);
  }, []);

  useEffect(() => {
    getAllDiagnosis();
  }, [getAllDiagnosis]);

  return {
    differentialDiagnosis,
    isdiagnosisLoading,
    getAllDiagnosis,
  };
};

export default useGetAllDiagnosis;
