import React from "react";
import SecondaryButton from "../../../../../common-components/Buttons/SecondaryButton";
import FileUpload from "../../../../../../images/file_upload.svg";
import TertiaryButton from "../../../../../common-components/Buttons/TertiaryButton";
import PrimaryButton from "../../../../../common-components/Buttons/PrimaryButton";
import Modal from "../../../../../common-components/Modal";

const AddOPconsultation = ({
  setActiveHealthTab = () => {},
  activeHealthTab,
}) => {
  return (
    <div className="text-sm text-[#2D2E33">
      <Modal
        showModal={activeHealthTab === "op_consultation" ? true : false}
        onClose={() => setActiveHealthTab("")}
        modalHeight="min-h-1/2"
        modalWidth="w-[90%]"
      >
        <div className="py-4 px-6">
          <h1 className="text-[#2D2E33 text-base  font-medium">
            OP Consultation
          </h1>
          <div className="flex py-4">
            <div className="w-8/12">
              <div className="mb-5">
                <h1 className="font-medium bg-[#ECECED] py-1 rounded ps-4">
                  Details
                </h1>
                <div className="flex ps-4 mt-2 flex-wrap">
                  <div className="my-2 w-1/2">
                    <div className="text-[#5E6066] mb-2.5">Allergy</div>
                    <div>
                      <input
                        type="text"
                        placeholder="Allergy"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/2">
                    <div className="text-[#5E6066] mb-2.5">History</div>
                    <div>
                      <input
                        type="text"
                        placeholder="History"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/2">
                    <div className="text-[#5E6066] mb-2.5">Symptoms</div>
                    <div>
                      <input
                        type="text"
                        placeholder="Symptoms"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/2">
                    <div className="text-[#5E6066] mb-2.5">
                      Observations/ Vitals
                    </div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                        placeholder="Observations/ Vitals"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/2">
                    <div className="text-[#5E6066] mb-2.5">Diagnosis</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                        placeholder="Diagnosis"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/2">
                    <div className="text-[#5E6066] mb-2.5">Treatment Plan</div>
                    <div>
                      <input
                        type="text"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                        placeholder="Treatment Plan"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/2">
                    <div className="text-[#5E6066] mb-2.5">Prescription</div>
                    <div>
                      <input
                        type="text"
                        placeholder="Prescription"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                      />
                    </div>
                  </div>
                  <div className="my-2 w-1/2">
                    <div className="text-[#5E6066] mb-2">Follow Up Date</div>
                    <div>
                      <input
                        type="date"
                        placeholder="Follow Up Date"
                        className="pb-1 placeholder:text-[#C6C7C9] text-[#2D2E33] focus:outline-none border-b w-10/12"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 justify-end pt-5">
                <TertiaryButton buttonName="Reset" width="w-fit" />
                <PrimaryButton buttonName="Submit" width="w-fit" />
              </div>
            </div>
            <div className="text-[#7F8185] flex flex-col items-center w-[10%] justify-center">
              <div className="flex flex-col items-center">
                <div className="border-l h-48"></div>
                <div>or</div>
                <div className="border-l h-48"></div>
              </div>
            </div>
            <div className="border-dashed h-fit border gap-3 flex flex-col rounded-lg py-6 px-12 bg-[#F9FAFB] border-[#C6C7C9] justify-center items-center">
              <div className="flex gap-2 justify-center flex-col">
                <img
                  src={FileUpload}
                  alt="FileUpload"
                  className="h-12 w-12 mx-auto"
                />
                <h1 className="text-[#2D2E33] text-sm">
                  Drag & Drop File to Upload
                </h1>
                <h1 className="text-[#7F8185] mx-auto">.png/ .jpg/ .pdf</h1>
              </div>
              <div>or</div>
              <SecondaryButton buttonName="Browse Files" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddOPconsultation;
