import React from "react";
import useGetHPIrecord from "../patient-details/hooks/useGetHPIrecord";
import Skeleton from "react-loading-skeleton";
// const paragraph = `Jai kumar with the age of 30 years presents with a headache that began 1 week ago. The pain is localized to the forehead and temples and has been constant since onset. The headache is described as a dull ache. The patient reports that physical activity, bright lights, and loud noises aggravate the headache, while rest in a dark, quiet room provides some relief.`;

const PresentIllnesHistory = () => {
  const { isHPIloading, hpiData } = useGetHPIrecord();
  // const hpiDataLength = hpiData.length;
  const hopi = hpiData[hpiData.length - 1];
  const { risk } = hopi || {};
  const { synopsis } = risk || {};
  const startIndex =
    synopsis?.indexOf("Medical Case Synopsis:") +
    "Medical Case Synopsis:".length; // Start after the key;
  const result = synopsis?.substring(startIndex);

  return (
    <div className="mt-2 text-sm font-light text-[#1F2A37]">
      {/* <div className="flex flex-wrap">{paragraph}</div> */}
      {/* <div className="my-2">
        The headache is associated with occasional nausea, mild photophobia, and
        mild phonophobia. The patient has been taking Ibuprofen 200 mg three to
        four times daily, which provides limited relief. There are no visual
        disturbances reported.
      </div>
      <div>
        The patient has a medical history of seasonal allergies but no known
        drug allergies. They note that increased work stress due to project
        deadlines may be contributing to the severity of the headache.
      </div> */}
      {isHPIloading ? (
        <div>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <Skeleton key={index} />
          ))}
        </div>
      ) : (
        <div>{result || "No Data found !"}</div>
      )}
    </div>
  );
};

export default PresentIllnesHistory;
