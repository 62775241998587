import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

const useGetAllCompliants = () => {
  const [chiefComplaints, setChiefComplaints] = useState([]);
  const [isChiefComplaintsLoading, setIsChiefComplaintsLoading] =
    useState(true);

  const getAllChiefComplaints = useCallback(async (inputValue = "") => {
    const token = localStorage.getItem("authToken");
    try {
      const res = await axios.get(`/v2/cortex/chiefComplaints/search`, {
        params: { keyword: inputValue },
        headers: {
          Authorization: token,
          "ngrok-skip-browser-warning": "hgs",
        },
      });
      setChiefComplaints(res.data);
    } catch (error) {
      console.error(error, "error");
      toast.error("Oops somthing went wrong", {
        position: "top-center",
      });
    }
    setIsChiefComplaintsLoading(false);
  }, []);

  useEffect(() => {
    getAllChiefComplaints();
  }, [getAllChiefComplaints]);

  return {
    chiefComplaints,
    isChiefComplaintsLoading,
    getAllChiefComplaints,
  };
};

export default useGetAllCompliants;
