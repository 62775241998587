import React, { memo } from "react";
import Select from "../../../common-components/Select";
import {
  DURATION,
  FREQUENCY_COUNT,
  MEDICATION_MEASUREMENT,
  TIMING,
} from "../../../constants/Constant";
import {
  faAngleDown,
  faAngleUp,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatArrayOfObjectForDrug } from "../../../utils/formateArray";
import useGetAllDrug from "./hooks/useGetAllDrug";
import SearchAndSelectDrug from "../../../common-components/SearchAndSelectDrug";

const MedicationsData = ({
  rxList = [],
  handleInputChange = () => {},
  removeMedicationField = () => {},
}) => {
  const { isDrugListLoading, drugList, getAllDrugList } = useGetAllDrug();

  return (
    <div>
      {rxList.map((item, index) => (
        <div key={index} className="flex mb-2 gap-5">
          <div className="w-full text-sm">
            <div className="text-[#111928] my-1">Medication {index + 1}</div>

            <SearchAndSelectDrug
              options={formatArrayOfObjectForDrug(drugList)}
              isLoading={isDrugListLoading}
              getData={getAllDrugList}
              currentIndex={index}
              defaultOptions={{
                label: item.drugName,
                value: item.drugName,
                lingo: item.companyLingo,
              }}
              // className="focus:outline-none rounded-lg ps-2 pe-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm  w-full focus:border-[#006AF5]"
            />
          </div>

          <div className="w-[50%] text-sm">
            <div className="text-[#111928] my-1">Dosage</div>
            <div className="flex w-full">
              <div className="w-[50%]">
                <input
                  name="dose"
                  onChange={(e) => handleInputChange(e, index)}
                  placeholder="Dose"
                  value={item.dose || ""}
                  className="border w-full items-center border-[#D1D5DB] py-1.5 px-2 focus:border-[#2D2E33] text-sm text-gray-800 rounded-r-none rounded-lg focus:outline-none"
                />
              </div>
              <div className="w-[50%]">
                <Select
                  showIcon={false}
                  options={MEDICATION_MEASUREMENT}
                  name="measurement"
                  onChange={(e) => handleInputChange(e, index)}
                  upIcon={faAngleUp}
                  placeholder="m. unit"
                  downIcon={faAngleDown}
                  className="border w-full items-center border-[#D1D5DB] border-l-0 py-1.5 px-2 focus:border-[#2D2E33] text-sm text-gray-800 rounded-l-none rounded-lg focus:outline-none"
                  defaultOption={{
                    label: item.measurement,
                    value: item.measurement,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-[70%] text-sm">
            <div className="text-[#111928] my-1">Timing</div>
            <Select
              options={TIMING}
              name="timing"
              onChange={(e) => handleInputChange(e, index)}
              upIcon={faAngleUp}
              downIcon={faAngleDown}
              placeholder="Timing"
              showIcon={false}
              defaultOption={{ label: item.timing, value: item.timing }}
            />
          </div>
          <div className="w-1/2 text-sm">
            <div className="text-[#111928] my-1">Frequency</div>
            <Select
              onChange={(e) => handleInputChange(e, index)}
              options={FREQUENCY_COUNT}
              upIcon={faAngleUp}
              downIcon={faAngleDown}
              name="frequency"
              placeholder="Frequency"
              showIcon={false}
              defaultOption={{ label: item.frequency, value: item.frequency }}
            />
          </div>
          <div className="w-[30%] text-sm">
            <div className="text-[#111928] my-1">Duration</div>
            <Select
              onChange={(e) => handleInputChange(e, index)}
              options={DURATION}
              upIcon={faAngleUp}
              downIcon={faAngleDown}
              placeholder="Duration"
              showIcon={false}
              name="duration"
              defaultOption={{ label: item.duration, value: item.duration }}
            />
          </div>
          <div className="w-[70%] text-sm">
            <div className="text-[#111928] my-1">Notes</div>
            <div>
              <textarea
                rows={1}
                name="notes"
                value={item.notes || ""}
                placeholder="Add medication notes"
                onChange={(e) => handleInputChange(e, index)}
                className="focus:outline-none rounded-lg  min-h-9 px-2 border placeholder:text-[#9CA3AF] border-[#D1D5DB] text-sm w-full focus:border-[#2D2E33]"
              />
            </div>
          </div>
          {rxList.length > 1 && (
            <div className="flex mt-5 justify-center">
              <button
                className=" px-1 active:animate-bounce rounded border-[#E7F4FF]"
                onClick={() => removeMedicationField(index)}
              >
                <FontAwesomeIcon icon={faTrashCan} color="#E02424" />
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default memo(MedicationsData);
