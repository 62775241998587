import React from "react";

const PreventiveCare = () => {
  return (
    <div>
      <div className="flex w-full py-3 text-[#374151] text-sm">
        <div className="w-[70%]">
          <div>Influenza Adult</div>
        </div>
        <div className="w-[30%] flex justify-center">17/05/2024</div>
      </div>
      <div className="flex w-full text-[#374151] text-sm">
        <div className="w-[70%]">
          <div>Pfizer COVID-19</div>
        </div>
        <div className="w-[30%] flex justify-center">17/05/2024</div>
      </div>
    </div>
  );
};

export default PreventiveCare;
