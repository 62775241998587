import axios from "axios";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js";

const useSavePrescription = ({ method = "post", contentRef }) => {
  const [isPrescriptionSaving, setIsPrescriptionSaving] = useState(false);
  const prescriptionData = useSelector((state) => state.prescriptionData);
  const [pastPrescription, setPastPrescription] = useState([]);

  const onSavePrescription = useCallback(
    async (id) => {
      setIsPrescriptionSaving(true);
      const token = localStorage.getItem("authToken");
      try {
        const res = await axios[method](
          `/v2/scroll/prescription`,
          method === "post"
            ? {
                patient: id,
                prescription: prescriptionData,
              }
            : {
                params: {
                  patient: id,
                },
                headers: {
                  Authorization: token,
                },
              },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setPastPrescription(res.data);
        if (method === "post") {
          toast.success("Prescription Generated Successfully!", {
            className: "w-max",
            position: "top-center",
          });
          const element = contentRef.current;
          const opt = {
            margin: 6,
            filename: "prescription.pdf",
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: {
              orientation: "p",
              unit: "mm",
              format: "a4",
              putOnlyUsedFonts: true,
              floatPrecision: 16, // or "smart", default is 16
            },
          };

          html2pdf().set(opt).from(element).save();
        }
      } catch (error) {
        toast.error(
          // error?.response?.data?.message || "Oops! somthing went wrong !",
          method === "get"
            ? { className: "w-max top-20", position: "top-center" }
            : {
                className: "w-max",
                position: "top-center",
              }
        );
      }
      setIsPrescriptionSaving(false);
    },
    // eslint-disable-next-line
    [method]
  );

  return { onSavePrescription, isPrescriptionSaving, pastPrescription };
};

export default useSavePrescription;
