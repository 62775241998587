import React from "react";

const CurrentMedications = () => {
  return (
    <div className="w-full">
      <div className="flex w-full py-4 text-[#374151] text-sm">
        <div className="w-[70%]">
          <div>RKESTRA L (Levosulipride (75mg) + Rabeprazole (20mg)</div>
          <div className="mt-1 font-light">
            1 tablet | 1-0-1 | After meal | 7d
          </div>
        </div>
        <div className="w-[30%] flex justify-center">17/05/2024</div>
      </div>
      <div className="flex w-full text-[#374151] text-sm">
        <div className="w-[70%]">
          <div>SEAGEL SUSP (Flucanazole)</div>
          <div className="mt-1 font-light">
            1 tablet | 1-0-1 | After meal | 7d
          </div>
        </div>
        <div className="w-[30%] flex justify-center">Unknown</div>
      </div>
    </div>
  );
};

export default CurrentMedications;
